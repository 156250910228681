import {useMemo, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {io} from 'socket.io-client';
import type {Game, GameUserInternal, REST_API} from './api';

function App() {
    const [partyInput, setPartyInput] = useState(
        () => 'testgame_' + Math.random().toString(36),
    );
    const [user, setUser] = useState<GameUserInternal>();
    const [game, setGame] = useState<Game>();
    const createGame = async () => {
        const res = await fetch('/parties/router/$/game', {
            method: 'POST',
            body: JSON.stringify({
                game_name: partyInput,
            }),
        });
        if (!res.ok) {
            return console.error(await res.text());
        }
        const data = (await res.json()) as REST_API['game']['POST']['Response'];
        setUser(data.user);
        setGame(data.game);
        console.log(data);
    };
    const joinGame = async () => {
        const pairing_code = partyInput;
        const res = await fetch('/parties/router/$/game', {
            method: 'PUT',
            body: JSON.stringify({
                pairing_code,
            }),
        });
        if (!res.ok) {
            return console.error(await res.text());
        }
        const data = (await res.json()) as REST_API['game']['PUT']['Response'];
        setUser(data.user);
        setGame(data.game);
        console.log(data);
    };
    return (
        <div id="app">
            <div>
                <input
                    value={partyInput}
                    onChange={(e) => setPartyInput(e.target.value)}
                />
            </div>
            <div>
                <button onClick={createGame}>Create Game</button>
                <button onClick={joinGame}>Pairing Code</button>
            </div>
            <div>{user && game && <HostGame user={user} game={game} />}</div>
        </div>
    );
}

function HostGame({user, game}: {user: GameUserInternal; game: Game}) {
    const {socket, received} = useSocket(game.party_id, {
        user_id: user.user_id,
        user_secret: user.user_secret,
    });
    return (
        <div>
            <pre>{JSON.stringify(game, null, '  ')}</pre>
            <pre>{JSON.stringify(user, null, '  ')}</pre>
            <pre>
                socket:{' '}
                {socket.connected
                    ? 'connected'
                    : socket.io._readyState === 'opening'
                    ? 'connecting'
                    : 'disconnected'}
            </pre>
            <pre>{JSON.stringify(received, null, '  ')}</pre>
        </div>
    );
}

function useSocket(partyId: string, auth: {user_id: string; user_secret: string}) {
    const [received, setReceived] = useState<any[]>([]);
    const socket = useMemo(() => {
        if (!partyId) throw Error('partyId required');
        const s = io({
            query: {partyId},
            transports: ['websocket'],
            auth,
        });
        s.onAny((name, data, id) => {
            console.log('event', name, data, id);
            setReceived((x) => [...x, [name, data, id]]);
        });
        s.io.on('reconnect', () => {
            console.log('reconnected');
            setReceived((x) => [...x, 'reconnected']);
        });
        return s;
    }, [partyId]);
    return {socket, received};
}

createRoot(document.getElementById('root')!).render(<App />);
